import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.min.css'
import './index.css';

import ReactDOM from 'react-dom/client';
import App from './App';
import "./i18n";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if(window.REACT_APP_API_URL){
  Sentry.init({
    dsn: "https://c60aaf82383b4d7c94ea112a91cbf1c1@o469240.ingest.sentry.io/6726075",
    integrations: [new BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <App />
);
