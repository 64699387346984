import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import { IAxiosResponse } from "../types/axios-response";
import {
  TDynamicHomeSettingResponse,
  THomeSettingUpdatePayload,
} from "../types/dynamic-home";
export const dynamicHomeService = {
  getSetting() {
    return AxiosInstance.get<IAxiosResponse<TDynamicHomeSettingResponse>>(
      API_ROUTES.DYNAMIC_HOME.SETTINGS,
    );
  },
  updateSetting(data: THomeSettingUpdatePayload) {
    return AxiosInstance.put<IAxiosResponse<any>>(
      API_ROUTES.DYNAMIC_HOME.SET_SETTING,
      data,
    );
  },
};
