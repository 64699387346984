import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { InputNumber } from "primereact/inputnumber";
import { getSectionTitle, settingItem } from "../../helper";
import { MIN_COUNT, MAX_COUNT } from "../../RelocationData/index";
type Props = {
  task: settingItem;
  onCountChange: (key: string, value: number) => void;
};

const Task = (props: Props) => {
  const { task, onCountChange } = props;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: task.id });
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    touchAction: "none",
    maxWidth: 300,
    marginBottom: 8,
    cursor: "grab",
  };
  return (
    <div style={style} ref={setNodeRef} {...attributes}>
      <div style={{ height: 48 }} className="p-inputgroup flex-1">
        <span
          className="p-inputgroup-addon justify-content-start  flex-1"
          {...listeners}>
          {getSectionTitle(task.key)}
        </span>
        <InputNumber
          placeholder="Count"
          value={task.count}
          onChange={(e) => {
            const value = e.value;
            if (typeof value === "number") {
              onCountChange(task.key, value);
            } else {
              onCountChange(task.key, 0);
            }
          }}
          mode="decimal"
          showButtons
          min={MIN_COUNT}
          max={MAX_COUNT}
        />
      </div>
    </div>
  );
};

export default Task;
