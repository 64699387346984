export const API_ROUTES = {
  BASE_URL: "/",
  AUTH: {
    LOGIN: "/admin/auth/login",
    GET_PROFILE: "/admin/auth/profile",
    LOGOUT: "/admin/auth/logout",
    FORGOT_PASSWORD: "/admin/auth/forgot-password",
    RESET_PASSWORD: "/admin/auth/reset-password",
    CHANGE_PASSWORD: "/admin/auth/change-password",
    REFRESH_TOKEN: "admin/auth/refresh",
  },
  ADMINISTRATORS: {
    ROOT: "/admin/administrators",
    NEW: "/admin/auth/register",
    TIMELINE: "/admin/timeline",
  },
  CUSTOMERS: {
    ROOT: "/admin/customers",
    EXPORT: "/admin/customers/export",
    CREATE: "/admin/customers/create",
    REMOVE: (id: number) => `/admin/customers/remove/${id}`,
    JOBS: "/admin/jobs",
    EDIT_JOB: (id: number) => `/admin/jobs/${id}`,
    TOGGLE_JOB: (id: number, targetStatus: boolean) => {
      if (targetStatus === true) {
        return `/admin/jobs/active/${id}`;
      }
      return `/admin/jobs/de-active/${id}`;
    },
    DELETE_JOB: (id: number) => `/admin/jobs/delete/${id}`,
  },
  FREELANCERS: {
    ROOT: "/admin/freelancers",
    EXPORT: "/admin/freelancers/export",
    CREATE: "/admin/freelancers/create",
    SERVICES: `/admin/services/`,
    SERVICE: (id: number) => `/admin/services/${id}`,
    PACKAGE: (id: number) => `/admin/packages/${id}`,
    EDIT_EDUCATION: (userId: number, id: number) =>
      `/admin/profile/${userId}/educations/${id}`,
    EDIT_EXPERIENCE: (userId: number, id: number) =>
      `/admin/profile/${userId}/experiences/${id}`,
    EDIT_LANGUAGES: (userId: number, selectedLanguage: number) =>
      `/admin/profile/${userId}/languages/${selectedLanguage}`,
    EDIT_SKILLS: (userId: number, selectedSkillId: number) =>
      `/admin/profile/${userId}/skills/${selectedSkillId}`,
    EDIT_LINKED_ACCOUNT: (userId: number, linkedAccountId: number) =>
      `/admin/profile/${userId}/linked-accounts/${linkedAccountId}`,
    EDIT_PACKAGE: (packageId: number) => `/admin/packages/${packageId}`,
    EDIT_SERVICE: (serviceId: number) => `/admin/services/${serviceId}`,
    REMOVE: (id: number) => `/admin/freelancers/remove/${id}`,
  },
  CONTRACTS: {
    ROOT: "/admin/contracts",
    EXPORT: "/admin/contracts/export",
    GET_AVAILABLE_ACTIONS: (id: number) =>
      `/admin/contracts/${id}/available-actions`,
    CHANGE_STATE: (id: number) => `/admin/contracts/${id}/state-action`,
  },
  BASE_DATA: {
    LIST_INFO: "/list-infos",
    SERVICE_TAGS: "/tags",
    SERVICE_CATEGORIES: "/categories",
    TIME_UNITS: "/timeUnits",
    GET_LINKED_ACCOUNTS: `/admin/linkedAccountTypes/`,
    GET_SKILLS: `/skills`,
    GET_SKILL_LEVEL: `/skillLevels`,
    GET_LANGUAGES: "/languages",
    COUNTRIES: "/countries",
    CITIES: "/admin/cities",
    CITIES_MINI_LIST: "/cities/mini-list",
    DEGREES: "/degrees",
    EMPLOYMENT_TYPES: "/employmentTypes",
  },
  USER: {
    CHANGE_ACTIVATION: (id: number) => `/admin/user/${id}/activation`,
    UPDATE: (id: number) => `/admin/user/${id}`,
  },
  CATEGORIES: {
    ROOT: "/admin/categories",
    ADD_NEW: "/admin/categories/create",
    UPDATE: (categoryId: number) => `/admin/categories/${categoryId}`,
    CHANGE_ACTIVATION: (id: number) => `/admin/categories/${id}/activation`,
  },
  FEE: {
    ROOT: "/admin/fees",
  },
  FINANCIAL: {
    TRANSACTION: {
      GET_TRANSACTIONS: "/admin/accounting/transactions",
      EXPORT_TRANSACTIONS: "/admin/accounting/transactions/export",
    },
    PAYMENTS: {
      GET_ALL: "/admin/payments",
      EXPORT: "/admin/payments/export",
    },
  },
  GLOBAL_SETTINGS: {
    SKILLS: "/admin/skills",
    LANGUAGES: "/admin/languages",
    DEGREES: "/admin/degrees",
    LANGUAGE_LEVELS: "/admin/languageLevels",
    SKILL_LEVELS: "/admin/skillLevels",
    TIME_UNITS: "/admin/time-units",
  },
  PROMOTION: {
    COUPON_LIST: `/admin/coupons`,
    COUPON_DETAILS: (id: number) => `/admin/coupons/${id}/detail`,
    GENERATE_RANDOM_CODE: `/admin/coupons/generate`,
    CREATE_COUPON: `/admin/coupons/create`,
    REVOKE_TOKEN: (id: number) => `/admin/coupons/${id}/revoke`,
  },
  STATIC_PAGES: {
    ROOT: "/admin/static-pages",
  },
  DYNAMIC_HOME: {
    SETTINGS: "/admin/home-page/get-setting",
    SET_SETTING: "/admin/home-page/section",
  },
};
